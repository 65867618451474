import { AppShell, useMantineTheme } from "@mantine/core";
import AppHeader from "./Header";
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";

export default function Layout() {
  const theme = useMantineTheme();
  const herf = useNavigate();

  const matches = useMediaQuery("(min-width: 56.25em)");

  useEffect(() => {
    herf("new");
  }, [herf]);

  return (
    <AppShell
      navbarOffsetBreakpoint="md"
      asideOffsetBreakpoint="md"
      navbar={matches ? <Navbar /> : undefined}
      header={<AppHeader theme={theme} />}
      children={<Outlet />}
    />
  );
}
