import { LoadingOverlay, MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import Router from "./Router";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export default function App() {
  return (
    <MantineProvider
      theme={{
        defaultRadius: 1,
        fontFamily: "Roboto, sans-serif",
        white: "#F5F8F5",
        // white: "#F6F6F6",
        // white: "#F6F5F0",
        // black: "#5D6D7E",
        black: "#4A5167",

        colors: {
          // override dark colors to change them for all components

          dark: [
            "#d5d7e0",
            "#acaebf",
            "#8c8fa3",
            "#666980",
            "#4d4f66",
            "#34354a",
            "#2b2c3d",
            "#1d1e30",
            "#0c0d21",
            "#01010a",
          ],
        },

        fontSizes: {
          xs: "xs",
          sm: "sm",
          md: "md",
          lg: "lg",
          xl: "xl",
        },

        // primaryColor: colors[lotto.id],
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Suspense fallback={<LoadingOverlay visible={true} overlayBlur={2} />}>
        <RouterProvider router={Router} />
      </Suspense>
    </MantineProvider>
  );
}
