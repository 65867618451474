import { Button, Navbar, Stack } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

export interface SideTabsLink {
  label: string;
  link: string;
}



const links: SideTabsLink[] = [
  { label: "5合1最新版", link: "new" },
  { label: "單彩經典版", link: "old" },
];

export default function NavbarX() {
  const local = useLocation();
  const route = local.pathname.split("/")[1];

  const href = useNavigate();

  const items = links.map((item) => (
    <Button
      // size="md"
      color="teal"
      onClick={(event) => {
        event.preventDefault();
        href(item.link);
      }}
      variant={route === item.link ? undefined : "outline"}
      fullWidth
      key={item.label}
    >
      {item.label}
    </Button>
  ));

  return (
    <Navbar w="20vh" height={500} p="xs">
      {/* <Box h={70}></Box> */}
      <Stack>{items}</Stack>
    </Navbar>
  );
}
