import {
  Header,
  Group,
  MantineTheme,
  Title,
  Text,
  Menu,
  ThemeIcon,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconMenu2 } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  theme: MantineTheme;
}

export default function AppHeader({ theme }: Props) {
  // const { classes } = useStyles();

  const local = useLocation();
  const route = local.pathname.split("/")[1];
  const matches = useMediaQuery("(min-width: 56.25em)");
  const href = useNavigate();

  return (
    <Header height={56} mb={120}>
      {/* <Container className={classes.inner} fluid> */}
      <Group position="apart">
        <Group position="apart">
          <Group>
            <Title
              variant="gradient"
              gradient={{ from: "teal", to: "blue", deg: 60 }}
            >
              中原
            </Title>
            <Text
              variant="gradient"
              gradient={{ from: "teal", to: "blue", deg: 60 }}
              size="xs"
            >
              {" "}
              X8
            </Text>
          </Group>
        </Group>
        <Group>
          {" "}
          <Text
            variant="gradient"
            gradient={{ from: "teal", to: "blue", deg: 60 }}
            size={12}
            fw={600}
          >
            {" "}
            安全 快捷 服務
          </Text>
          {!matches && (
            <Group>
              <Menu
                // position="bottom-end"
                transitionProps={{ transition: "pop-bottom-left" }}
                withinPortal
              >
                <Menu.Target>
                  <ThemeIcon color="teal" size={40} radius={5}>
                    <IconMenu2 />
                  </ThemeIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => href("new")}>
                    <Text
                      h={28}
                      size="lg"
                      fw={600}
                      bg={route === "new" ? "teal" : undefined}
                      color={route === "new" ? "white" : undefined}
                    >{`5合1最新版`}</Text>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item>
                    <Text
                      h={28}
                      fw={600}
                      size="lg"
                      onClick={() => href("old")}
                      bg={route === "old" ? "teal" : undefined}
                      color={route === "old" ? "white" : undefined}
                    >{`單彩經典版`}</Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          )}
        </Group>
      </Group>
      {/* </Container> */}
    </Header>
  );
}
