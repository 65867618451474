import { createBrowserRouter } from "react-router-dom";
import AppMain from "./Old";
import Layout from "./Layout";
import New from "./New";

const Router = createBrowserRouter([
  {
    path: "/",

    element: <Layout />,
    children: [
      {
        path: "new",
        element: <New />,
      },
      {
        path: "old",
        element: <AppMain />,
      },
    ],
  },
]);

export default Router;
