import { createStyles, rem, Text, Center, Container } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  footer: {
    // marginTop: rem(120),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function AppFooter() {
  const { classes } = useStyles();

  return (
    <Container className={classes.footer}>
      <Center mt={30}>
        <Text color="dimmed" size={12}>
          電子郵件 📧: mt8888899999@protonmail.com
        </Text>
      </Center>
      <Center>
        <Text color="dimmed" size={12}>
          {`TW © ${new Date().getFullYear()}  Trading System. All rights reserved.`}
        </Text>
      </Center>
    </Container>
  );
}
