import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import {
  createStyles,
  Paper,
  Text,
  Title,
  useMantineTheme,
  rem,
  ThemeIcon,
  Group,
  Container,
} from "@mantine/core";
import o2 from "./o2.png";

import { BsFillSendFill } from "react-icons/bs";
import AppFooter from "./AppFooter";

const useStyles = createStyles((theme) => ({
  card: {
    height: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
    color: "teal",
    lineHeight: 1.2,
    fontSize: rem(16),
    marginTop: theme.spacing.xs,
  },

  category: {
    color: "black",
    opacity: 0.7,
    fontWeight: 500,
    fontSize: 12,

    textTransform: "uppercase",
  },
}));

interface CardProps {
  image: string;
  title: string;
  category: string;
}

function Card({ image, title, category }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      shadow="md"
      // p="xl"
      radius="md"
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <div>
        <Title order={3} className={classes.title} color="grey">
          {category}
        </Title>
        <Text className={classes.category} size="xs" color="grey">
          {title}
        </Text>
      </div>

      <Group spacing={3}>
        <Text className={classes.category} size="xs" color="grey">
          Telegram 電報
        </Text>

        <ThemeIcon
          size={14}
          children={<BsFillSendFill size={12} />}
        ></ThemeIcon>

        <Text className={classes.category} size="xs" color="grey">
          : mt8888899999
        </Text>

        <Text className={classes.category} size="xs" color="grey">
          可測試,可試用,可定制,有需求聯係!
        </Text>
      </Group>
    </Paper>
  );
}

const data2 = [
  {
    image: o2,
    title: "php版本, 能支持3000人同時在綫,適合單彩玩家",
    category: "1. 經典版本",
  },
];

export default function Old() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const slides2 = data2.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <>
      <Container>
        <Carousel
          slideSize="100%"
          breakpoints={[{ maxWidth: "sm", slideSize: "100%" }]}
          slideGap="xs"
          align="start"
          slidesToScroll={mobile ? 1 : 1}
        >
          {slides2}
        </Carousel>
      </Container>
      <AppFooter />
    </>
  );
}
