import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import {
  createStyles,
  Paper,
  Text,
  Title,
  useMantineTheme,
  rem,
  ThemeIcon,
  Group,
  Container,
} from "@mantine/core";
import P1 from "./1.png";
import P2 from "./2.png";
import P3 from "./3.png";
import P4 from "./4.png";
import P5 from "./5.png";
import P6 from "./6.png";
import P7 from "./7.png";
import P8 from "./8.png";
import P9 from "./9.png";
import P10 from "./10.png";
import P11 from "./11.png";
import P12 from "./12.png";
import P13 from "./13.png";
import P14 from "./14.png";
import P15 from "./15.png";
import P16 from "./16.png";
import P17 from "./17.png";
import P18 from "./18.png";
import P19 from "./19.png";
import P20 from "./20.png";
import P21 from "./21.png";
import P22 from "./22.png";
import P23 from "./23.png";
import P24 from "./24.png";
import { BsFillSendFill } from "react-icons/bs";
import AppFooter from "./AppFooter";

const useStyles = createStyles((theme) => ({
  card: {
    height: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
    color: "teal",
    lineHeight: 1.2,
    fontSize: rem(16),
    marginTop: theme.spacing.xs,
  },

  category: {
    color: "black",
    opacity: 0.7,
    fontWeight: 500,
    fontSize: 12,
    textTransform: "uppercase",
  },
}));

interface CardProps {
  image: string;
  title: string;
  category: string;
}

function Card({ image, title, category }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      shadow="md"
      // p="xl"
      radius="md"
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <div>
        <Title order={3} className={classes.title} color="grey">
          {category}
        </Title>
        <Text className={classes.category} size="xs" color="grey">
          {title}
        </Text>
      </div>

      <Group spacing={3}>
        <Text className={classes.category} size="xs" color="grey">
          Telegram 電報
        </Text>

        <ThemeIcon
          size={14}
          children={<BsFillSendFill size={12} />}
        ></ThemeIcon>

        <Text className={classes.category} size="xs" color="grey">
          : mt8888899999
        </Text>
        <Text className={classes.category} size="xs" color="grey">
          可測試,可試用,可定制, 有需求聯係!
        </Text>
      </Group>
    </Paper>
  );
}

const data = [
  {
    image: P1,
    title: "五彩十盤,可任意選擇彩種,盤口<最多可選5個彩種,10個盤口>",
    category: "1. C++版本 ",
  },
  {
    image: P2,
    title: "訂單.貨量.種類.一覽無疑",
    category: "2. 專業面板",
  },
  {
    image: P3,
    title: "傻瓜式操作界面,讓您的客戶滿意",
    category: "3. 操作簡單",
  },
  {
    image: P4,
    title: "專業服務器技術團隊,可以大幅度消除ddos等攻擊帶來的影響",
    category: "4. 防止攻擊",
  },
  {
    image: P5,
    title: "一流UI團隊打造 ",
    category: "5. UI界面友好",
  },
  {
    image: P6,
    title: "完美支持 手機,IPAD 等移動設備",
    category: "6. 移動設備",
  },
  {
    image: P7,
    title: "代理端跟後臺一樣的彩盤監控,數據操作功能强大",
    category: "7. 特色功能",
  },
  {
    image: P8,
    title: "集中式一體用戶界面",
    category: "8. 用戶管理",
  },
  {
    image: P9,
    title: "一鍵輕鬆備份,數據可見,讓您和客戶不必擔憂資料被篡改",
    category: "9. 數據備份",
  },
  {
    image: P10,
    title:
      "采用c++程式語言,安全和效率無與倫比,萬人在綫流暢<可做分佈系統部署,最高在綫人數無上限>",
    category: "10. 開發語言",
  },
  {
    image: P11,
    title: "采用尖端技術,可保證斷網頁面不卡頓,等待網絡恢復,不用重新登入",
    category: "11. 程式效率",
  },
  {
    image: P12,
    title: "采用知名大牌機房,全球cnd部署,保證聯通率99.99%",
    category: "12. 網絡部署",
  },
  {
    image: P13,
    title: "采用as256 加密傳輸, 可保證資料傳輸途中不被盜取",
    category: "13. 程式安全 ",
  },
  {
    image: P14,
    title: "多地備份數據庫,確保您的資料永固",
    category: "14. 數據庫",
  },
  {
    image: P15,
    title: "多年的經營經驗纍積,讓程式快捷操作更加人性化",
    category: "15. 快捷操作",
  },
  {
    image: P16,
    title: "簡單易懂,數據展現詳盡",
    category: "16. 用戶報表 ",
  },
  {
    image: P17,
    title: "采用穩定虛擬貨幣按月交易,最大限度保護您的隱私",
    category: "17. 租用方式",
  },

  {
    image: P18,
    title: "全程專人監控,排除服務器隱患",
    category: "18. 服務",
  },
  {
    image: P19,
    title: "深淺主題配色,盡可能地迎合大衆的口味",
    category: "19.深淺主題",
  },
  {
    image: P20,
    title: "每個彩都擁有自己的專屬色彩,讓客戶容易分辨彩種",
    category: "20. 顔色系統 ",
  },
  {
    image: P21,
    title: "采用業界前沿的react框架",
    category: "21. 前端技術",
  },
  {
    image: P22,
    title: "年肖可以一鍵切換,賠率自動更換",
    category: "22. 年肖更換",
  },
  {
    image: P23,
    title: "代理端全級別可以操盤,賺取賠率,控制單量",
    category: "23. 全級別操盤",
  },
  {
    image: P24,
    title: "跟代理補貨一樣方便地操作,有需求可以做跨站互調",
    category: "24. 後臺外調功能",
  },
];

export default function New() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <>
      <Container>
        <Carousel
          slideSize="100%"
          breakpoints={[{ maxWidth: "sm", slideSize: "200%" }]}
          slideGap="xs"
          align="start"
          slidesToScroll={mobile ? 1 : 1}
        >
          {slides}
        </Carousel>
      </Container>
      <AppFooter />
    </>
  );
}
